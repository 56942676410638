/*! http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
/* Box model */
*, *:before, *:after {
	-moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box;
}

/* Clearfix */
.cf:before,
.cf:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.cf:after {
    clear: both;
}

/* Reset HTML5 Search Input in Webkit */
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration,
input[type=search]::-webkit-search-results-button,
input[type=search]::-webkit-search-results-decoration {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  border-radius: 0;
}
input[type=search] {
  -webkit-appearance:textfield;
  -webkit-box-sizing:content-box;
  -webkit-border-radius: 0;
  border-radius: 0;
}
input {
    -webkit-appearance: none;
    border-radius: 0;
}
input[type='checkbox'] {
    -webkit-appearance: checkbox;
    border-radius: 0;
}
input:focus {
  outline: 0;
}
::-webkit-input-placeholder { /* WebKit browsers */
    color:    #a2a2a2;
    font-weight: normal;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color:    #a2a2a2;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color:    #a2a2a2;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
   color:    #a2a2a2;
}
html {
  -webkit-text-size-adjust: 100%;
}
textarea,
input[type="text"],
input[type="search"],
input[type="button"],
input[type="submit"],
button {
     -webkit-appearance: none;
     border-radius: 0;
     border: 0;
}

