@include mq-add-breakpoint(extrawide, 1420px);

html {
	-webkit-overflow-scrolling: touch;
}

body {
	font-family: $basefont;
	font-weight: 400;
	color: $textcolor;
	min-width: 320px;
	
	@include mq($from: tablet) {
		//padding-top: 150px;
	}
	
	@include mq($from: desktop) {
		//padding-top: 170px;
	}
	
	@include mq($from: wide) {
		//padding-top: 190px;
	}
}

body.no-scrolling, html.no-scrolling {
	overflow: hidden;
}

html,
body {
	overflow-x: hidden;
}

img,
embed,
object,
video {
	height: auto;
	max-width: 100%;
}

iframe {
	max-width: 100%;
}

.button {

}

main {
	clear: both;
	overflow: hidden;
}

/* Structuur */
.wrapper {
	display: block;
	width: 100%;
	height: auto;
	
	@include mq($from: tablet) {
		margin: 0 auto;
	}
	
	@include mq($from: desktop) {
	}
	
	@include mq($from: wide) {
		max-width: 1300px;
	}
}

/* Rows */
.content-row {
	position: relative;
	display: block;
	
	&.row-white {
		background-color: #fff;
	}
	
	&.row-primary {
		background-color: rgba($primary, .6);
		color: #fff;
		
		h1, h2, h3, h4, h5, h6 {
			color: #fff;
		}
	}
	
	&.row-secondary {
		background-color: $secondary;
		color: #fff;
		
		h1, h2, h3, h4, h5, h6 {
			color: #fff;
		}
	}
	
	&.row-tertiary {
		background-color: $tertiary;
	}
	
	&.row-quaternary {
		background-color: $quaternary;
	}
	
	&.row-quintenary {
		background-color: $quintenary;
		
		h1, h2, h3, h4, h5, h6, p {
			color: #fff;
		}
	}
	
	&.row-align-centered { text-align: center; }
	&.row-align-left { text-align: left; }
	&.row-align-right { text-align: right; }
	
	&.row-has-bgimage {
		background-position: top center;
		background-size: cover;
		padding: 75px 0;
	}
	
	&.row-has-scroller {
	}
	
	@include mq($from: tablet) {
		
		&.row-has-scroller {
		}
		
		&.row-has-bgimage {
			padding: 150px 0;
		}
	}
	
	@include mq($from: desktop) {
		
		&.row-width-medium .wrapper .row-text {
			width: 75%;
			margin: 0 auto;
		}
		
		&.row-width-small .wrapper .row-text {
			width: 50%;
			margin: 0 auto;
		}
	}
	
	@include mq($from: wide) {
	}
}

.content-row {
	
	.row-text {
		width: 100%;
	}
	
	.text-wrapper .main-content {
		padding: 25px 15px;
	}
	
	.text .main-spliced-content.spliced-text,
	.row-text .main-spliced-content.spliced-text {
		padding: 10px 0;
	}
	
	.spliced-image {
		text-align: center;
	}
	
	@include mq($from: tablet) {
		
		.text-wrapper .main-content {
			padding: 25px;
		}
		
		.text .text-wrapper,
		.row-text .text-wrapper {
			display: flex;
			flex-wrap: wrap;
		}
		
		.text .main-content,
		.row-text .main-content {
			flex: 1;
			
			&.spliced-image {
				order: 2;
			}
		}
		
		.text .main-spliced-content,
		.row-text .main-spliced-content {
			flex: 0 0 30%;
			padding-top: 0;
			
			&.spliced-image {
				order: 1;
				padding: 0;
				align-self: flex-end;
			}
		}
	}
	
	@include mq($from: desktop) {
		
		.text-wrapper .main-content {
			padding: 25px 35px;
		}
	}
	
	@include mq($from: wide) {
		
		.text-wrapper .main-content {
			padding: 50px 25px;
		}
	}
}

.scroll-down {
	position: absolute;
	bottom: -20px;
	left: 50%;
	height: 40px;
	width: 40px;
	line-height: 40px;
	border-radius: 40px;
	color: $primary;
	background-color: #fff;
	font-size: 1.2em;
	text-align: center;
	margin-left: -20px;
	box-shadow: 0 3px 5px #ccc;
	z-index: 150;
	transition: all .25s ease-in-out;
	
	&:hover {
		background-color: $secondary;
		box-shadow: 0 3px 10px #ccc;
		color: #fff;
	}
}

/* Rijen */
@import 'rows/row-slider';
@import 'rows/row-columns';
@import 'rows/row-latest-news';
@import 'rows/row-gallery';
@import 'rows/row-map';
@import 'rows/row-buttons';
@import 'rows/row-employees';

.text.title-align-default h1 { text-align: left; }
.text.title-align-center h1,
.text.title-align-center h2,
.text.title-align-center h3,
.text.title-align-center .category-list { text-align: center; }
.text.title-align-default h1,
.text.title-align-default h2,
.text.title-align-default h3,
.text.title-align-default .category-list { &:after { left: 0; } }
.text.title-align-right h1 { text-align: right; }
.text.title-bold h1 { font-weight: bold; }
.text.title-uppercase h1 { text-transform: uppercase; }

.text {
	
	@include mq($from: desktop) {
		&.content-width-default {
			width: 100%;
			margin: 0 auto;
		}

		&.content-width-medium {
			width: 75%;
			margin: 0 auto;
		}
		
		&.content-width-small {
			width: 50%;
			margin: 0 auto;
		}
	}
}

/* Division */
.page-template-division .row-type-division .text-wrapper {
	
	@include mq($from: tablet) {
		display: flex;
		flex-wrap: wrap;
	}
}

.division-main-content {
	padding: 15px;
	
	&.background-color-primary {
		background-color: rgba($primary, .6);
		color: #fff;
		
		h1, h2, h3, h4, h5, h6, p, ul, ol {
			color: #fff;
		}
	}
	
	&.background-color-secondary {
		background-color: $secondary;
		color: #fff;
		
		h1, h2, h3, h4, h5, h6, p, ul, ol {
			color: #fff;
		}
	}
	
	&.background-color-tertiary {
		background-color: $tertiary;
		
		h1, h2, h3, h4, h5, h6, p, ul, ol {
			color: #fff;
		}
	}
	
	&.background-color-quaternary {
		background-color: $quaternary;
		
		h1, h2, h3, h4, h5, h6, p, ul, ol {
			color: #fff;
		}
	}
	
	&.background-color-senary {
		background-color: $senary;
		
		h1, h2, h3, h4, h5, h6, p, ul, ol {
			color: #fff;
		}
	}
	
	&.background-color-quintenary {
		background-color: $quintenary;
		
		h1, h2, h3, h4, h5, h6, p, ul, ol {
			color: #fff;
		}
	}
	
	&.background-color-septenary {
		background-color: $septenary;
		
		h1, h2, h3, h4, h5, h6, p, ul, ol {
			color: #fff;
		}
	}
	
	> div > h1 {
		display: flex;
		flex-wrap: wrap;
		
		> img {
			height: 40px;
			width: auto;
			margin-right: 10px;
		}
	}

	@include mq($from: tablet) {
		width: 50%;
		order: 2;
		padding: 45px 50px;
	}
	
	@include mq($from: wide) {
		> div {
			max-width: 600px;
		}
	}
}

.division-extra-content {
	padding: 15px;
	
	@include mq($from: tablet) {
		width: 50%;
		order: 1;
		padding: 45px 50px;
	}
	
	@include mq($from: wide) {
		> div {
			max-width: 600px;
			margin-left: auto;
		}
	}
}

/* Category */
.category-items {
	text-align: left;
	padding: 0 20px;
	
	.owl-stage {
		display: flex;
		flex-wrap: nowrap;
		
		.owl-item {
			display: flex;
			height: auto;
		}
	}
	
	&.owl-carousel .owl-stage {
		//padding-left: 0 !important;
	}
	
	.owl-stage-outer {
	}
	
	.owl-nav {
		position: absolute;
		bottom: 50%;
		right: 0;
		left: 0;
	}
	
	.owl-nav .owl-prev,
	.owl-nav .owl-next {
		position: absolute;
		top: 50%;
		left: 0;
		height: 25px;
		width: 25px;
		line-height: 25px;
		margin-top: -12px;
		text-align: center;
		color: #fff;
		font-size: 1em;
		outline: none;
		background-color: $primary;
		pointer-events: all;
		transition: all .25s ease-in-out;
		
		&:hover {
			background-color: $secondary;
			color: #fff;
		}
		
		&.disabled {
			opacity: .5;
			cursor: not-allowed;
			
			&:hover {
				background-color: #fff;
				color: $textcolor;
			}
		}
	}
	
	.owl-nav .owl-next {
		left: auto;
		right: 0;
	}

	@include mq($from: tablet) {
		padding: 0 60px;
		
		.owl-nav {
			bottom: 50%;
			right: 5px;
			left: 5px;
		}
		
		.owl-nav .owl-prev,
		.owl-nav .owl-next {
			height: 40px;
			width: 40px;
			line-height: 40px;
			border-radius: 40px;
			font-size: 1.4em;
			color: $primary;
			background-color: transparent;
		}
	}
}

.post-navigation {
	text-align: center;
	margin-top: 20px;
}

.category-item {
	position: relative;
	display: block;
	height: 100%;
	width: 100%;
	text-decoration: none;
	background-color: #f8f8f8;
	padding: 15px;
	padding-bottom: 35px;
	color: $textcolor;
	transition: background-color .25s ease-in-out;
	
	.featured-image {
		position: relative;
		width: 100%;
		overflow: hidden;
		background-color: #fff;
		padding-bottom: 58%;
		z-index: 4;
		
		&::before {
			content: "\f067";
			font-family: $fontawesome;
			font-weight: 900;
			font-size: 1.3em;
			text-align: center;
			line-height: 50px;
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			height: 50px;
			width: 50px;
			margin-left: -25px;
			margin-top: -25px;
			border-radius: 50px;
			color: #fff;
			background-color: $primary;
			transform: scale(0);
			z-index: 5;
			transition: all .25s ease-in-out;
		}
		
		&::after {
			content: " ";
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			border: 10px solid #d5d5d5;
			transition: all .25s ease-in-out;
			z-index: 15;
		}
		
		img {
			position: absolute;
			z-index: 1;
			transition: all .75s ease-in-out;
		}
	}
	
	.category-item-content {
		position: relative;
		padding: 15px 0;
		z-index: 5;
		
		h2 {
			color: $primary;
			transition: color .25s ease-in-out;
		}
		
		.category-item-content__summary {
			color: $textcolor;
			margin-bottom: 10px;
		}
	}
	
	.tags {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 5;
	}
	
	.tags .tag {
		display: inline-block;
		color: #fff;
		border-radius: 0;
		padding: 5px;
		margin-right: 1px;
		font-size: .85em;
		background-color: $primary;
		transition: all .25s ease-in-out;
	}
	
	.read-on {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		padding: 0 15px 15px 15px;
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		line-height: 30px;
		width: 100%;
		transition: color .25s ease-in-out;
	}
	
	.read-on > span {
		font-weight: 600;
		text-decoration: underline;
	}
	
	.read-on i {
		margin-left: auto;
		color: $primary;
		height: 30px;
		width: 30px;
		border: 2px solid $primary;
		border-radius: 30px;
		text-align: center;
		line-height: 27px;
	}
	
	&.category-item-backgroundcolor-white { background-color: #fff; }
	&.category-item-backgroundcolor-primary {
		background-color: $primary;
		color: #fff;
		
		h2 {
			color: #fff;
		}
	}
	&.category-item-backgroundcolor-secondary {
		background-color: $secondary;
		color: #fff;
		
		h2 {
			color: #fff;
		}
	}
	&.category-item-backgroundcolor-tertiary {
		background-color: $tertiary;
		color: #fff;
		
		h2 {
			color: #fff;
		}
	}
	&.category-item-backgroundcolor-quaternary {
		background-color: $quaternary;
		color: $secondary;
		
		h2 {
			color: $primary;
		}
	}
	&.category-item-backgroundcolor-quintenary {
		background-color: $quintenary;
		color: #fff;
		
		h2 {
			color: #fff;
		}
	}
	
	&:hover {
		background-color: $primary;
		color: #fff;
		
		h2 {
			color: #fff;
		}
		
		.read-on {
			color: #fff;
		}
		
		.featured-image::before {
			transform: scale(1);
		}
		
		.featured-image::after {
			border: 1px solid #fff;
		}
		
		.featured-image img {
			transform: scale(1.05);
		}
		
		.tag {
			transform: translateY(-30px);
		}
		
		.read-on i {
			border-color: #fff;
			color: #fff;
		}
	}
	
	@include mq($from: wide) {
		display: flex;
		flex-wrap: wrap;
		flex-direction: row;
		
		.featured-image {
			width: 40%;
			align-self: flex-start;
			padding-bottom: 23%;
		}
		
		.category-item-content {
			width: 60%;
			font-size: .9em;
			padding-top: 0;
			padding-left: 15px;
		}
		
		.read-on {
			left: 40%;
			width: auto;
		}
	}
}

.tags .tag {
	display: inline-block;
	color: #fff;
	border-radius: 0;
	padding: 5px;
	margin-right: 1px;
	font-size: .85em;
	background-color: $primary;
	transition: all .25s ease-in-out;
}
	

// Op category.php worden de category items niet in een owl carousel geladen, daarom afwijkende stijling
.category-items.no-carousel {
	padding: 20px;
	
	@include mq($from: minitablet) {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		
		&::after {
			content: "";
			flex-basis: 48%;
		}
	}
	
	@include mq($from: tablet) {
		padding: 25px 50px;
	}
	
	@include mq($from: desktop) {
		padding: 50px;
		
		&::after {
			flex-basis: 48%;
		}
	}
}

.category-items.no-carousel .category-item {
	width: 100%;
	height: auto;
	margin-bottom: 15px;
	
	@include mq($from: tablet) {
		width: 48%;
		margin-bottom: 30px;
	}
	
	@include mq($from: desktop) {
		width: 48%;
		margin-bottom: 50px;
	}
}

.category-items-taglist {
	
	.tag.active a {
		background-color: $primary;
		color: #fff;
	}
	
	@include mq($until: tablet) {
		.openlist {
			display: block;
			color: #fff;
			text-decoration: none;
			font-size: 1.2em;
			background-color: $primary;
			padding: 10px 15px;
			border-bottom: 1px solid darken($primary, 10%);
			
			&::after {
				display: inline-block;
				content: "\f078";
				font-family: $fontawesome;
				font-weight: 900;
				font-size: .65em;
				margin-left: 7px;
				height: 17px;
				width: 17px;
				line-height: 19px;
				text-align: center;
				color: #fff;
				transition: transform .25s ease-in-out;
			}
		}
	}
	
	@include mq($from: tablet) {
		
		.openlist {
			display: block;
			text-align: center;
			margin-top: 30px;
			font-size: 1.2em;
			font-weight: 600;
			color: $textcolor;
			text-decoration: none;
		}
	}
}

.taglist {
	display: none;
	margin: 0;
	list-style: none;
	
	li a  {
		display: block;
		color: #fff;
		text-decoration: none;
		background-color: darken($primary, 5%);
		padding: 7px 15px;
		border-bottom: 1px solid darken($primary, 10%);
	}
	
	li a:hover {
		background-color: $secondary;
	}
	
	@include mq($from: tablet) {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		padding: 15px 30px;
		
		li a {
			display: inline-block;
			margin: 3px;
			font-size: .85em;
			padding: 4px 7px;
			color: $textcolor;
			background-color: darken($quaternary, 10%);
			border: 0;
			border-radius: 10px;
			transition: all .25s ease-in-out;
		}
		
		li a:hover {
			background-color: $primary;
			color: #fff;
		}
	}
}

.social-share {
	display: flex;
	flex-wrap: wrap;
	list-style: none;
	margin: 20px 0;
	
	> span {
		color: $primary;
		font-size: 1.2em;
		font-weight: 600;
		margin-right: 15px;
	}
	
	.fb-share-button {
		margin-right: 10px;
		
		> span {
			vertical-align: top !important;
		}
	}
	
	.twitter-share-button {
		margin-right: 10px;
	}
	
	.linkedin-share {
		margin-right: 10px;
	}
}

.post-navigation {
	position: relative;
	display: flex;
	padding: 5px;
	margin-bottom: 20px;
	justify-content: center;
	
	.page-numbers {
		width: 37px;
		height: 37px;
		line-height: 37px;
		border-radius: 37px;
		color: #fff;
		margin: 0 3px;
		background-color: $primary;
		text-align: center;
		text-decoration: none;
		transition: all .25s ease-in-out;
		
		&.dots {
			background-color: transparent;
			color: $textcolor;
		}
		
		i {
			font-size: .85em;
			margin-left: 5px;
			margin-right: 0;
		}
		
		&.prev i {
			margin-left: 0;
			margin-right: 5px;
		}
		
		&:not(.current):hover {
			background-color: $secondary;
			color: #fff;
		}
		
		&.current {
			background-color: $secondary;
			cursor: not-allowed;
		}
	}
	
	.prev-page.disabled span,
	.prev-page.disabled a,
	.next-page.disabled span,
	.next-page.disabled a {
		background-color: #ccc;
		color: #999;
		cursor: not-allowed;
	}
	
	.page-numbers.next,
	.page-numbers.prev {
		position: absolute;
		width: auto;
		padding: 0 15px;
	}
	
	.page-numbers.prev { left: 0; }
	.page-numbers.next { right: 0; }
	
	.page-numbers:not(.next):not(.prev):not(.current):hover {
		background-color: #fff;
		color: $primary;
		box-shadow: 0 0 10px rgba(#000, .5);
		transform: scale(1.05);
	}
	
	@include mq($from: tablet) {
		margin-bottom: 30px;
	}
	
	@include mq($from: desktop) {
		margin-bottom: 50px;
	}
}

.post-summary {
	margin-bottom: 20px;
}

.back-to-top {
	position: fixed;
	bottom: 10px;
	right: 10px;
	background-color: #fff;
	color: $primary;
	padding: 10px;
	text-align: center;
	border-radius: 3px;
	box-shadow: 0 0 15px rgba(#000, .3);
	transform: scale(.3);
	opacity: 0;
	visibility: hidden;
	transition: all .15s ease-in-out;
	z-index: 50;
	
	&.show-me {
		transform: scale(1);
		visibility: visible;
		opacity: 1;
	}
	
	@include mq($from: desktop) {
		display: none;
	}
}

@import 'content/search';

@media print {
	header,
	footer,
	.slider,
	.sidebar,
	.video-footer,
	.quick-selector {
		display: none;
	}
	
	img {
		max-width: 250px;
	}
}