.row-type-employees {
	padding: 20px 0;
	
	.text-wrapper > h1 {
		color: $primary;
		font-size: 1.4em;
	}
	
	.employee-list {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 15px;
		text-align: left;
	}
	
	.employee-list .employee-item {
		width: 48%;
		padding: 10px;
		margin-bottom: 15px;
		background-color: darken($tertiary, 8%);
	}
	
	.employee-list .employee-item .employee-content {
		padding: 10px 0;
	}
	
	.employee-list .employee-item h2 {
		font-size: 1rem;
		color: $textcolor;
		margin-bottom: 0;
	}
	
	@include mq($from: tablet) {
		.text-wrapper > h1 {
			font-size: 1.6em;
		}
		
		.employee-list .employee-item {
			width: 24%;
		}
	}

	@include mq($from: desktop) {

		.text-wrapper > h1 {
			font-size: 1.8em;
		}
		
		.employee-list .employee-item {
			width: 17%;
			margin: 0 3%;
			margin-bottom: 40px;
		}
	}
}