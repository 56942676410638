.row-type-map {
	//min-height: 250px;
	
	.wrapper {
		padding-bottom:  60%;
	}
	
	.markers {
		display: none;
	}
	
	#google-map {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
	
	.infowindow-content-wrapper {
		padding: 10px 10px;
	}
	
	.infowindow-content-wrapper .buttons {
		min-width: 300px;
		
		.button {
			margin-right: 5px;
		}
	}
	
	@include mq($from: tablet) {
		//min-height: 350px;
		
		.wrapper {
			padding-bottom:  50%;
		}
	}
	
	@include mq($from: desktop) {
		.wrapper {
			padding-bottom: 30%;
		}
	}
}