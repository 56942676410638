/*!
Theme Name: Dierenkliniek Den Ham
Theme URI: dierenkliniekdenham.nl
Description: Thema voor Dierenkliniek Den Ham
Author: Danny Jasper
Version: 1.0
*/

// Breakpoints
$mq-responsive: true;
$mq-breakpoints: (
	mobile:  320px,
	bigmobile:  365px,
	minitablet:  480px,
	tablet:  740px,
	desktop: 975px,
	wide:    1220px,
	mobileLandscape: 668px
);
$mq-static-breakpoint: desktop;
@import 'mq';

// Standaard
@import 'vars';
@import 'reset';

// Scripts
@import 'lib/magnific-popup';
@import 'lib/owl.carousel';
@import 'lib/hamburger';

// Fonts
@import url("https://use.typekit.net/zxa6uul.css");

// Extra styles
@import 'content';
@import 'forms';

// Globale stylesheet
@import 'layout/header';
@import 'layout/footer';
@import 'global';