/* Forms */
.gform_wrapper {
	margin: 0;
	
	ul {
		margin: 0;
		list-style: none;
	}
	
	.validation_error {
		display: block;
		line-height: 1.3;
		margin-bottom: 20px;
	}
	
	.validation_message {
		position: absolute;
		font-size: .75em;
		left: 5px;
		bottom: -18px;
	}
	
	.gform_description {
		display: block;
		margin-bottom: 15px;
	}
	
	.gform_ajax_spinner {
		position: absolute;
		top: 50%;
		left: 50%;
	}
	
	.gfield {
		position: relative;
		display: block;
		overflow: hidden;
		margin-bottom: 5px;
		
		&:last-child {
			margin-bottom: 0;
		}
	}
	
	label {
		display: block;
		color: inherit;
		text-align: left;
		margin-bottom: 10px;
	}
	
	.gfield_radio li {
		display: inline-block;
		width: auto;
		
		input[type='radio'] {
			margin-right: 5px;
		}
	}
	
	.gfield_radio label {
		display: inline-block;
		font-size: 1.2em;
		width: auto;
	}
	
	input[type='text'],
	input[type='email'],
	input[type='tel'],
	input[type='password'],
	input[type='number'],
	input[type='search'],
	select {
		display: block;
		width: 100%;
		padding: 10px 15px;
		border-radius: 4px;
		font-size: inherit;
		background-color: #f8f8f8;
		color: $textcolor;
		border: 0;
		transition: background-color .25s linear;
		
		&:focus {
			background-color: #e7e7e7;
		}
		
		&::-webkit-input-placeholder { color: $textcolor; }
		&::-moz-placeholder { color: $textcolor; }
		&:-ms-input-placeholder { color: $textcolor; }
		&:-moz-placeholder { color: $textcolor; }
	}
	
	.gfield_error {
		input[type='text'],
		input[type='email'],
		input[type='tel'],
		input[type='password'],
		input[type='number'],
		input[type='search'],
		textarea {
			border: 1px solid red;
		}
	}
	
	select {
		height: 40px;
		background-color: #dedede;
	}
	
	input[type='number'] {
		width: auto;
		display: inline-block;
		padding: 3px;
	}
	
	input[type='checkbox'] {
		display: inline-block;
	}
	
	input[type='radio'] {
		display: inline-block;
		width: 15px;
		height: 15px;
		vertical-align: middle;
		-webkit-appearance: radio;
	}
	
	textarea {
		display: block;
		width: 100%;
		min-height: 150px;
		height: 150px;
		border: 0;
		padding: 15px;
		border-radius: 4px;
		font-family: $basefont;
		color: $textcolor;
		font-size: inherit;
		margin-bottom: 10px;
		background-color: #f8f8f8;
		transition: border .25s linear, background-color .25s linear;
		
		&:focus {
			background-color: #e7e7e7;
			outline: 0;
		}
	}
	
	input[type='submit'] {
		background-color: transparent;
		border: 2px solid #fff;
		line-height: 1;
	}
	
	@include mq($from: tablet) {
		
		> form {
			display: flex;
			flex-wrap: wrap;
		}
		
		.gform_heading {
			width: 100%;
		}
		
		.gform_body {
			flex-grow: 1;
		}
		
		.gform_footer {
			width: auto;
			display: flex;
			flex-direction: column;
		}
		
		.gform_footer button.submit {
			margin-top: auto;
		}
		
		.gfield {
			display: flex;
			flex-wrap: wrap;
		}
		
		.gfield > .gfield_label {
			width: 35%;
			vertical-align: middle;
			margin: 0;
		}
		
		.gfield .ginput_container {
			flex-grow: 1;
		}
	}
	
	.two-columns {
		
		input[type='text'],
		input[type='email'] {
		}
		
		input[type='submit'] {
			height: 30px;
			width: auto;
			float: none;
			margin-left: auto;
		}
		
		textarea.textarea {
			width: 100% !important;
			min-height: 125px;
			height: 125px;
		}
		
		.gform_footer {
			width: 100%;
			align-content: flex-end;
		}
		
		@include mq($from: tablet) {
			
			.gform_fields {
				column-count: 2;
			}
			
			.gfield {
				break-inside: avoid;
			}
			
			.ginput_container.ginput_container_textarea {
				width: 65%;
			}
		}
		
		@include mq($until: desktop) {
			.gfield_label {
				width: 100%;
			} 
		}
		
		@include mq($from: desktop) {
			input[type='text'],
			input[type='email'] {
				width: 100%;
			}
			
			textarea {
			}
		}
		
		@include mq($from: wide) {
			
			textarea {
			}
		}
	}
}

/* Background colors */
.row-quaternary {
	.gform_wrapper {
		input[type='text'],
		input[type='email'],
		input[type='tel'],
		input[type='password'],
		input[type='number'],
		input[type='search'],
		select,
		textarea {
			background-color: #fff;
		}
	}
}

/* Buttons */
input[type='submit'],
input[type='button'],
button.submit {
	display: inline-block;
	font-size: 1em;
	font-weight: 600;
	line-height: 1.7;
	color: #fff;
	padding: 5px 12px;
	margin-bottom: 5px;
	border-radius: 20px;
	cursor: pointer;
	font-family: $basefont;
	background-color: darken($primary, 12%);
	transition: background-color .25s ease-in-out,
							color .25s ease-in-out,
							padding .25s ease-in-out;
	
	i {
		padding-left: 10px;
	}
	
	&:hover {
		background-color: $quintenary;
		color: #fff;
	}
}

.gform_validation_container {
	display: none !important;
}