.row-type-buttons {
	padding: 0;
	
	.row-buttons--wrapper {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}
	
	.row-button {
		width: 100%;
		padding: 10px 20px;
		color: #fff;
		text-decoration: none;
		
		&.row-button--backgroundcolor-primary { background-color: $primary; }
		&.row-button--backgroundcolor-secondary { background-color: $secondary; }
		&.row-button--backgroundcolor-tertiary { background-color: $tertiary; }
		&.row-button--backgroundcolor-quaternary { background-color: $quaternary; }
		&.row-button--backgroundcolor-quintenary { background-color: $quintenary; }
		&.row-button--backgroundcolor-senary { background-color: $senary; }
		&.row-button--backgroundcolor-septenary { background-color: $septenary; }
	}
	
	.row-button--image {
		display: none;
	}
	
	.row-button--content {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
	}
	
	.row-button--icon {
		max-width: 40px;
		
		img {
			width: 30px;
			height: auto;
		}
	}
	
	.row-button--text {
		flex-grow: 1;
		padding-left: 15px;
		text-align: left;
		line-height: 1.5;
		hyphens: auto;
		font-size: 1.2em;
	}
	
	
	@include mq($from: minitablet) {
	}
	
	@include mq($from: tablet) {
		margin-top: 7px;
		
		.row-button {
			width: 20%;
			padding: 0;
		}
	}
	
	@include mq($from: tablet, $until: desktop) {
		.row-button--icon {
			flex-grow: 1;
			flex-direction: row;
			width: 100%;
			max-width: 100%;
			text-align: center;
			min-height: 50%;
			
			img {
				max-width: 100%;
				max-height: 60px;
				margin: 25px 0;
				width: auto;
			}
		}
		
		.row-button--text {
			width: 100%;
			padding: 15px;
			text-align: center;
			font-size: 1em;
		}
	}
	
	@include mq($from: desktop) {
		
		.row-button--image {
			display: block;
		}
		
		.row-button--content {
			padding: 10px;
			justify-content: center;
		}
		
		.row-button--text {
			font-size: 1em;
			flex-grow: 0;
		}
		
		.row-button--icon img {
			max-height: 20px;
		}
		
		.row-button:hover .row-button-image img {
			transform: scale(1.1);
		}
	}
	
	@include mq($from: extrawide) {
		
		.row-button--icon img {
			max-height: none;
			height: 30px;
			width: auto;
		}
		
		.row-button--text {
			font-size: 1.2em;
		}
	}
}
