/* Slider */
.row-type-slider {
	z-index: 15;
	
	.default-slider {
		position: relative;
		height: auto;
		padding-bottom: 35%;
	}
	
	.owl-stage-outer {
		position: absolute;
	}
	
	.owl-item {
		overflow: hidden;
	}
	
	.slide {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	
	.slide-image {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-size: cover;
		background-position: center;
		overflow: hidden;
		z-index: 4;
	}
	
	.slide.slide-has-gradient:before {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		content: "";
		display: block;
		background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,0.7) 100%);
		background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 25%,rgba(0,0,0,0.7) 100%);
		background: radial-gradient(ellipse at center, rgba(0,0,0,0) 0%,rgba(0,0,0,0) 25%,rgba(0,0,0,0.7) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a6000000',GradientType=1 );
		pointer-events: none;
		z-index: 5;
	}
	
	.slide.slide-image-mirrored .slide-image {
		transform: scaleX(-1);
	}
	
	.owl-nav {
		position: absolute;
		z-index: 10;
		pointer-events: none;
		min-width: 130px;
		display: none;
	}
	
	.slider-nav-position-default .owl-nav {
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
	
	.slider-nav-position-right .owl-nav {
		top: 75%;
		right: 7%;
		left: 84%;
	}
	
	.slider-nav-position-left .owl-nav {
		top: 75%;
		left: 7%;
		right: 84%;
	}
	
	.owl-nav .owl-prev,
	.owl-nav .owl-next {
		position: absolute;
		top: 50%;
		left: 10px;
		height: 40px;
		width: 40px;
		line-height: 40px;
		border-radius: 40px;
		margin-top: -20px;
		text-align: center;
		background-color: #fff;
		color: $textcolor;
		font-size: 1em;
		outline: none;
		pointer-events: all;
		transition: all .25s ease-in-out;
		
		&:hover {
			background-color: $primary;
			color: #fff;
		}
	}
	
	.owl-nav .owl-next {
		left: auto;
		right: 10px;
	}
	
	.slider-nav-position-default {
		
	}
	
	.slide-video,
	.slide-text-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		z-index: 10;
	}
	
	.slide-text-wrapper .slide-text {
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		width: 80%;
		
		&.slide-text-align-left {
			text-align: left;
			left: 15px;
		}
		
		&.slide-text-align-right {
			text-align: right;
			right: 15px;
		}
		
		h1, p {
			margin: 0;
			color: #fff;
		}
		
		h1 {
			font-size: 1.4em;
			margin-bottom: 15px;
			line-height: 1;
		}
		
		p {
			display: none;
			margin-bottom: 15px;
		}
	}
	
	.buttons .button:first-child {
		margin-right: 10px;
	}
	
	.slide-text-wrapper {
		pointer-events: none;
	}
	
	.slide-text {
		pointer-events: all;
	}
	
	.slide-text-wrapper .wrapper {
		height: 100%;
		position: relative;
		pointer-events: none;
	}
	
	@include mq($from: minitablet) {
		.slide-text-wrapper .slide-text h1 {
			font-size: 1.8em;
		}
	}
	
	@include mq($from: tablet) {
		.owl-nav {
			display: block;
		}
		
		.slide-text-wrapper .slide-text {
			width: 50%;
			
			&.slide-text-align-left {
				left: 30px;
			}
			
			&.slide-text-align-right {
				right: 30px;
			}
		}
		
		.slide-text-wrapper .slide-text p {
			display: block;
			font-size: 1.2em;
		}
		
		.slide-text-wrapper .slide-text h1 {
			font-size: 2.6em;
		}
	}
	
	@include mq($from: desktop) {
		
		.slide-text-wrapper .slide-text {
			width: 35%;
		}
		
		.slide-text-wrapper .slide-text h1 {
			font-size: 3.2em;
		}
	}
	
	@include mq($from: wide) {
		.slide-text-wrapper .slide-text h1 {
			font-size: 4em;
		}
		
		.slide-text-wrapper .slide-text p {
			font-size: 1.4em;
		}
	}
}