.search-results {
	margin-top: 20px;
	width: 100%;
	
	.search-item {
		display: block;
		padding: 15px;
		background-color: #fff;
		margin-bottom: 20px;
		
		h2 {
			margin-bottom: 5px;
		}
		
		.summary {
			margin-bottom: 5px;
		}
		
		.shortlink-view {
			display: inline-block;
			color: lighten($textcolor, 50%);
			text-decoration: none;
		}
		
		.post-type-label {
			display: inline-block;
			background-color: $secondary;
			font-size: .8em;
			padding: 5px;
			color: #fff;
			margin-left: 5px;
		}
		
		.search-item-excerpt {
			margin: 10px 0;
		}
		
		&:hover {
			.shortlink-view {
				color: $secondary;
			}
		}
	}
}

.post-navigation {
	width: 100%;
}

.search-no-results {
	h1 {
		width: 100%;
	}
}