a {
	color: inherit;
	text-decoration: underline;
	transition: color .25s linear;
}

strong {
	font-weight: 700;
}

em {
	font-style: italic;
}

p > a {
	color: $primary;
}

h1, h2, h3, h4, h5, h6 {
	font-family: $headingfont;

	a {
		text-decoration: none;
		color: inherit;
	}
}

h1,
h2.as-h1 {
	position: relative;
	font-size: 2em;
	line-height: 1.1;
	margin-bottom: 15px;
	font-weight: 600;
	color: #c9c9c9;
	
	@include mq($from: tablet) {
		font-size: 2.4em;
		margin-bottom: 20px;
	}
}

h2,
h3.gform_title {
	font-size: 1.5em;
	line-height: 1.3;
	font-weight: 600;
	color: $primary;
	margin-bottom: 15px;
}

h3.gform_title {
	color: inherit;
}

h3 {
	font-size: 1.2em;
	font-weight: 600;
	line-height: 1.3;
	color: inherit;
}

h4 {
	font-size: 1.1em;
	line-height: 1.3;
	color: inherit;
}

h5 {
	font-size: 1em;
	line-height: 1.3;
	color: inherit;
}

p,
ol,
ul,
dl,
.gform_description {
	font-family: $basefont;
	font-size: 1em;
	line-height: 1.7;
	margin-bottom: 20px;
	color: inherit;
	
	&:last-child {
		margin-bottom: 0;
	}
}

p.intro-text {
	font-size: 1.2em;
	margin-bottom: 40px;
}

ol:last-child,
ul:last-child {
	margin-bottom: 0;
}

ol,
ul {
	margin-left: 20px;
}

ul {
	list-style: disc;
}

ol {
	list-style: decimal;
}

blockquote {
	padding: 10px;
	padding-left: 15px;
	margin-bottom: 20px;
	border-left: 3px solid #bfc2c4;
	transition: all .25s ease-in-out;
	
	p:last-child {
		margin: 0;
	}
	
	&:hover {
		background-color: #f8f8f8;
		border-color: $primary;
	}
}

.button {
	position: relative;
	display: inline-block;
	font-size: 1em;
	font-weight: 600;
	line-height: 1.7;
	color: #fff;
	padding: 5px 12px;
	margin-bottom: 5px;
	border-radius: 20px;
	text-decoration: none;
	transition: background-color .25s ease-in-out,
							color .25s ease-in-out,
							padding .25s ease-in-out;
							
	> a {
		display: block;
	}
	
	> i {
		margin-right: 5px;
		transition: margin-right .25s ease-in-out;
	}
	
	&:hover > i {
		margin-right: 10px;
	}
	
	&.button-primary {
		background-color: $primary;
		color: #fff;
		
		a,
		&:hover,
		&:hover a {
			color: #fff;
		}
		
		&:hover {
			background-color: $secondary;
		}
	}
	
	&.button-secondary {
		font-weight: bold;
		color: #fff;
		background-color: $secondary;
		
		a,
		&:hover,
		&:hover a {
			color: #fff;
		}
		
		&:hover {
			background-color: $primary;
		}
	}
	
	&.button-tertiary {
		color: #fff;
		background-color: $tertiary;
		
		a,
		&:hover,
		&:hover a {
			color: #fff;
		}
		
		&:hover {
			background-color: $primary;

		}
	}
	
	&.button-quaternary {
		color: $textcolor;
		background-color: $quaternary;
		
		a {
			color: $textcolor;
		}
		
		&:hover,
		&:hover a {
			color: #fff;
		}
		
		&:hover {
			background-color: $primary;
		}
	}
	
	&.button-white {
		background-color: #fff;
		border: 2px solid $tertiary;
		color: $primary;
		
		a {
			color: $primary;
		}
		
		&:hover {
			color: #fff;
			background-color: $secondary;
		}
		
		&:hover a {
			color: #fff;
		}
	}
	
	&.button-quintenary {
		background-color: $quintenary;
		
		&:hover,
		&:hover a {
			color: #fff;
		}
		
		&:hover {
			background-color: $secondary;
		}
	}
	
	a {
		text-decoration: none;
	}
}

.button {
	&::after {
		//content: '\f35d';
		font-family: $fontawesome;
		font-weight: 900;
		font-size: .85em;
		padding-left: 10px;
		margin-left: 0;
		transition: margin-left .25s ease-in-out;
	}
	
	&[href^="https://*.dieren"]::after,
	&[href^="/"]::after,
	&[href^="#"]::after {
		content: '\f061';
	}
	
	&[href^="tel:"]::after {
		content: '\f095';
	}
	
	&[href^="mailto:"]::after {
		content: '\f0e0';
	}
	
	&[href$=".pdf"]::after {
		content: '\f1c1';
	}
	
	&.map-link::after {
		content: '\f5a0';
	}
	
	&:hover::after {
		margin-left: 10px;
	}
}

.readmore,
.nav-previous a,
.nav-next a {
	position: relative;
	display: inline-block;
	width: auto;
	padding-right: 25px;
	text-decoration: none;
	color: $primary;
	
	&::after {
		position: absolute;
		right: 0;
		top: -3px;
		content: '\f144';
		font-size: 1.4em;
		font-family: $fontawesome;
		font-weight: 900;
		color: $primary;
		transition: all .25s ease-in-out;
	}
	
	&:hover,
	&:hover::after {
		color: $secondary;
	}
}

.nav-previous a,
.nav-next a {
	text-decoration: none;
	color: $secondary;
	
	&::after {
		color: $secondary;
	}
	
	&:hover,
	&:hover::after {
		color: $primary;
	}
}

.nav-previous a {
	padding-right: 0;
	padding-left: 25px;
	
	&::after {
		right: auto;
		left: 0;
		transform: rotate(180deg);
	}
}

/* =WordPress Core
-------------------------------------------------------------- */
.alignnone {
}

.alignright {
	float: none;
	display: block;
	margin-bottom: 15px;
	
	@include mq($from: tablet) { 
		float: right;
		margin: 0 0 15px 15px;
	}
}

.alignleft {
	float: none;
	display: block;
	margin-bottom: 15px;
	
	@include mq($from: tablet) { 
		float: left;
		margin: 0 15px 15px 0;
	}
}

.aligncenter {
	display: block;
	margin: 0 auto;
}

.gallery {
	display: block;
	width: 100%;
	margin-bottom: 20px;
}

.gallery .gallery-item {
	float: left;
	width: 29%;
	margin-right: 10px;
	max-height: 150px;
	margin-bottom: 10px;
	overflow: hidden;
	
	img {
		width: 100%;
		height: auto;
	}
	
	@include mq($from: desktop) {
		width: 29%;
	}
}

/* Kleuren / afwijkende details */
