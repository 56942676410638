.row-type-latest_news {
	position: relative;
	height: auto;
	padding: 20px 0;
	
	h1 {
		font-size: 1.4em;
		color: $primary;
	}
	
	.wrapper {
		position: relative;
	}
	
	.post-navigation {
		a {
			color: $primary;
		}
	}
	
	// Opmaak afzonderlijke items staat in global.scss, word hergebriukt op andere modules/rijen/pagina's.
	@include mq($from: minitablet) {
	}
	
	@include mq($from: tablet) {
		h1 {
			font-size: 1.6em;
		}
	}
	
	@include mq($from: desktop) {
		padding-bottom: 50px;
		
		h1 {
			font-size: 1.8em;
		}
		
		.post-navigation {
			width: auto;
			position: absolute;
			top: 0;
			right: 0;
			margin: 0;
		}
	}
	
	@include mq($from: desktop) {
	}
}
