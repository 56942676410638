@include mq-add-breakpoint(wide_extra, 1300px);

/* Header */
header.mainheader {
	position: relative;
	display: block;
	height: auto;
	z-index: 2500;
	transition: background-color .25s ease-in-out;
	
	@include mq($from: tablet) {
		//position: fixed;
		//top: 0;
		//left: 0;
		//right: 0;
		transition: all .25s ease-in-out;
	}
	
	@include mq($from: desktop) {
	}
}

.scrolled-down header.mainheader {
	
	@include mq($from: tablet) {
		//transform: translateY(-50px);
		backdrop-filter: blur(15px);
	//	background-color: rgba(#fff, .95);
	//	box-shadow: 0 0 50px rgba($secondary, .3);
	}
	
	@include mq($from: wide) {
	}
}

.header-top {
	position: relative;
	color: $textcolor;
	
	.wrapper {
		display: flex;
		flex-wrap: wrap;
	}
	
	// Orders
	.logo { order: 3; }
	.inline-search { order: 1; }
	.quick-navigation { order: 2; }
	.call-to-action { order: 4; }
	
	@include mq($from: tablet) {
		
		.logo { order: 1; }
		.inline-search { order: 2; }
		.quick-navigation { order: 3; }
		.call-to-action { order: 4; }
	}
	
	@include mq($from: tablet, $until: desktop) {
	}
	
	@include mq($from: desktop) {
		
		.wrapper {
			position: relative;
		}
	}
}

.logo {
	width: 60%;
	padding: 10px;
	transition: all .25s ease-in-out;
	
	img {
		max-width: 230px;
	}
	
	@include mq($from: minitablet) {
		padding: 15px 10px;
		width: 50%;
	}
	
	@include mq($from: tablet) {
		width: 30%;
	}
	
	@include mq($from: desktop) {
		padding-left: 5px;
	}
	
	@include mq($from: wide) {
	}
}

.header-top .call-to-action {
	width: 40%;
	max-height: 90px;
	display: flex;
	flex-wrap: wrap;
	text-decoration: none;
	
	.icon {
		color: $primary;
		width: 16%;
		background-color: #dcdcdc;
		width: 25px;
		height: 25px;
		line-height: 25px;
		border-radius: 25px;
		text-align: center;
	}
	
	.phone {
		display: block;
		width: 100%;
		color: $textcolor;
	}
		
	.phone-pretext {
		display: none;
		color: $textcolor;
	}
	
	.cta-text {
		padding-left: 5px;
		width: 76%;
	}
	
	@include mq($from: minitablet) {
		justify-content: flex-end;
		width: 50%;
		padding-top: 15px;
		
		.icon {
			width: 35px;
			height: 35px;
			line-height: 35px;
			border-radius: 35px;
		}
		
		.phone-pretext,
		.phone {
			display: block
		}
		
		.phone {
			font-weight: 700;
		}
		
		.cta-text {
			display: block;
			width: 55%;
		}
	}
	
	@include mq($from: tablet) {
		position: absolute;
		top: 55px;
		right: 0;
		padding-top: 0;
	}
	
	@include mq($from: desktop) {
		background-color: $primary;
		top: 0;
		right: 5px;
		width: 200px;
		padding: 15px 8px;
		border-radius: 0 0 20px 20px;
		
		.cta-text {
			width: 75%;
		}
		
		.phone-pretext {
			margin-bottom: 5px;
		}
		
		.phone-pretext,
		.phone {
			color: #fff;
		}
	}
}

.quick-navigation {
	width: 100%;
	
	ul {
		list-style: none;
		margin: 0;
		padding: 5px 10px;
		display: flex;
		flex-wrap: wrap;
	}
	
	ul li {
		width: auto;
		line-height: 1;
		vertical-align: middle;
		padding: 0 5px;
		border-left: 1px solid $secondary;
		
		&:first-child {
			padding-left: 0;
			border: 0;
		}
	}
	
	ul li a {
		display: block;
		vertical-align: middle;
		line-height: 1;
		color: $textcolor;
		text-decoration: none;
		
		&:hover {
			color: $secondary;
		}
	}
	
	@include mq($from: minitablet) {
		width: 60%;
		padding: 10px;
	}
	
	@include mq($from: tablet) {
		width: 40%;
	}
	
	@include mq($from: desktop) {
		padding-right: 180px;
	}
}

.inline-search {
	width: 100%;
	background-color: $primary;
	color: #fff;
	padding: 10px;
	text-align: left;
	
	input[type='search'] {
		padding: 7px 10px;
		font-size: .8em;
		color: #fff;
		width: 60%;
		max-width: 350px;
		background-color: #fff;
		border-radius: 3px;
		font-family: $basefont;
		
		&::placeholder {
			color: $textcolor;
		}
	}
	
	button {
		font-size: 1.2rem;
		color: #fff;
		border: 0;
		background: transparent;
	}
	
	button .button-text {
		display: none;
	}
	
	@include mq($from: minitablet) {
		position: relative;
		background-color: transparent;
		width: 40%;
		
		input[type='search'] {
			width: 100%;
			border-radius: 7px;
			background-color: #f2f2f2;
			color: #777777;
		}
		
		button {
			position: absolute;
			right: -10px;
			top: 13px;
			color: #777777;
		}
	}
	
	@include mq($from: tablet) {
		width: 30%;
	}
	
	@include mq($from: desktop) {
		width: 25%;
		padding-left: 10%;
		flex-grow: 1;
		
		input[type='search'] {
		}
	}
}

/* Navigatie */
.hamburger {
	position: fixed;
	top: 0;
	right: 0;
	height: 50px;
	width: 50px;
	color: #fff;
	background-color: $secondary;
	outline: none;
	z-index: 1001;
	transition: none .25s ease-in-out;
	
	.hamburger-inner,
	.hamburger-inner:before,
	.hamburger-inner:after {
		background-color: #fff;
		height: 4px;
		border-radius: 1px;
	}
	
	&.is-active {
		position: fixed;
		right: 0;
		top: 0;
		//margin: 0;
		border-radius: 0;
		background-color: #fff;
		
		.hamburger-inner,
		.hamburger-inner:before,
		.hamburger-inner:after {
			background-color: $secondary;
		}
	}
	
	@include mq($from: tablet) {
		display: none;
	}
}

.scrolled-down .hamburger {
}

nav.main-navigation {
	
	@include mq($until: tablet) {
		position: fixed;
		top: 50px;
		bottom: 0;
		left: 0;
		opacity: 0;
		width: 100%;
		max-width: 600px;
		transform: translateX(-100%);
		overflow-y: auto;
		overflow-x: visible;
		-webkit-overflow-scrolling: touch;
		visibility: hidden;
		background-color: rgba($primary, .95);
		backdrop-filter: blur(10px);
		transition: all .35s ease-in-out;
		z-index: 1000;
		
		.wrapper > ul {
			display: block;
			text-align: left;
			line-height: 1.3;
			margin: auto;
			padding: 0;
		}
		
		.wrapper > ul > li {
			display: block;
		}
		
		.wrapper > ul > li > a {
			position: relative;
			display: inline-block;
			width: 100%;
			padding: 10px 25px;
			font-size: 1.2em;
			color: #fff;
			background-color: $primary;
			text-decoration: none;
			border-bottom: 1px solid darken($primary, 5%);
		}
		
		.wrapper > ul > li.current-menu-item > a,
		.wrapper > ul > li:hover > a {
			color: #fff;
			background-color: darken($primary, 5%);
		}
		
		.wrapper > ul > li.menu-item-has-children > a:after {
			display: inline-block;
			content: "\f078";
			font-family: $fontawesome;
			font-weight: 900;
			font-size: .65em;
			margin-left: 7px;
			height: 17px;
			width: 17px;
			line-height: 19px;
			text-align: center;
			color: #fff;
			transition: transform .25s ease-in-out;
		}
		
		.wrapper > ul > li.menu-item-has-children > a.menu-item-open::after {
			transform: rotate(180deg);
		}
		
		.wrapper > ul > li {
			
			&.current-menu-item.gezelschapsdieren a { background-color: $secondary; }
			&.current-menu-item.paarden a { background-color: $quaternary; }
			&.current-menu-item.landbouwdieren a { background-color: $quintenary; }
			&.current-menu-item.apotheek-laboratorium a { background-color: $senary; }
			&.current-menu-item.veterinaire-mri a { background-color: $septenary; }
		}
		
		.wrapper > ul ul {
			display: none;
			background-color: darken($primary, 10%);
			margin: 0;
			
			li {
				list-style: none;
				width: 100%;
				display: block;
			}
			
			li.top-menu-link a {
				font-weight: 700;
			}
			
			li a {
				color: #fff;
				width: 100%;
				display: block;
				font-size: 1em;
				border-bottom: 1px solid $primary;
				padding: 5px 25px;
				text-decoration: none;
			}
			
			li:last-child a {
			}
		}
		
		.wrapper > ul > li > ul.sub-menu.open {
			display: block;
		}
	}
	
	@include mq($from: tablet) {
		background-color: $tertiary;
		
		ul {
			list-style: none;
			margin: 0;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
		}
		
		ul > li {
			position: relative;
			flex-grow: 1;
			
			&:hover.gezelschapsdieren a,
			&.current-menu-item.gezelschapsdieren a,
			&.current-page-ancestor.gezelschapsdieren a { background-color: $secondary; }
			&:hover.paarden a,
			&.current-menu-item.paarden a,
			&.current-page-ancestor.paarden a { background-color: $quaternary; }
			&:hover.landbouwdieren a,
			&.current-menu-item.landbouwdieren a,
			&.current-page-ancestor.landbouwdieren a { background-color: $quintenary; }
			&:hover.apotheek-laboratorium a,
			&.current-menu-item.apotheek-laboratorium a,
			&.current-page-ancestor.apotheek-laboratorium a { background-color: $senary; }
			&:hover.veterinaire-mri a,
			&.current-menu-item.veterinaire-mri a,
			&.current-page-ancestor.veterinaire-mri a { background-color: $septenary; }
		}
		
		ul > li > a {
			display: block;
			width: 100%;
			height: 100%;
			text-decoration: none;
			color: #5f5f5f;
			font-weight: 700;
			font-size: .85em;
			padding: 5px 10px;
			text-align: center;
			text-transform: uppercase;
			border-right: 1px solid #fff;
			transition: all .25s ease-in-out;
		}
		
		ul > li:hover > a,
		ul > li.current-menu-item > a,
		ul > li.current-page-ancestor > a {
			color: #fff;
		}
		
		ul > li:last-child a {
			border: 0;
		}
	}
	
	@include mq($from: desktop) {
		
		ul > li > a {
			font-size: 1em;
			padding: 7px 20px;
			align-items: center;
			justify-content: center;
		}
	}
	
	@include mq($from: wide) {
	}
}

nav.main-navigation.activated-small {
	display: block;
	bottom: 0;
	opacity: 1;
	transform: translateX(0);
	visibility: visible;
	
	@include mq($from: tablet) {
		overflow: hidden;
		transition: max-height 0.6s cubic-bezier(1,0,1,0);
		height: auto;
		max-height: 9999px;
	}
}