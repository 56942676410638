.row-type-columns {
	padding: 0;
	
	.column {
		width: 100%;
	}
	
	.column.column-background-color-white {
		background-color: #fff;
	}
	
	.column.column-background-color-primary {
		background-color: $primary;
		color: #fff;
	}
	
	.column.column-background-color-secondary {
		background-color: $secondary;
		color: #fff;
	}
	
	.column.column-background-color-tertiary {
		background-color: $tertiary;
	}
	
	.column.column-background-color-quaternary {
		background-color: $quaternary;
	}
	
	.column.column-background-color-quintenary {
		background-color: $quintenary;
		color: #fff;
	}
	
	.column-type-image {
		padding-bottom: 50%;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;
	}
	
	.column-type-text,
	.column-type-carousel {
		padding: 15px;
	}
	
	.column-type-carousel {
		position: relative;
	}
	
	.owl-dots {
		display: none;
	}
	
	@include mq($from: tablet) {
		.row-columns {
			display: flex;
			flex-wrap: wrap;
		}
		
		.column {
			width: 50%;
		}
		
		.column-type-text,
		.column-type-carousel {
			padding: 25px;
		}
		
		.column-carousel-items {
			padding-bottom: 20px;
		}
		
		.column-type-image {
			padding-bottom: 0;
		}
		
		.owl-dots {
			position: absolute;
			bottom: 0;
			right: 0;
			display: flex;
			flex-wrap: wrap;
			align-content: flex-end;
		}
		
		.owl-dots .owl-dot {
			width: 22px;
			height: 22px;
			margin-left: 4px;
			padding: 2px;
			background-color: #c9c9c9;
			background-clip: content-box;
			border-radius: 22px;
			border: 2px solid #adadad;
			transition: all .25s ease-in-out;
			
			&.active {
				background-color: $primary;
			}
		}
	}
	
	@include mq($from: wide) {
		
		.column-type-text,
		.column-type-carousel {
			padding: 50px 50px;
		}
	}
}