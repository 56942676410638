$primary: #006845; // Groen
$secondary: #df8f30; // Oranje (gezelschapsdieren)
$tertiary: #f1f1f1; // Lichtgrijs
$quaternary: #9f684e; // Paarden (bruin)
$quintenary: #53aada; // Landbouwhuisdieren (blauw)
$senary: #84cfc2; // Lichtblauw (Apotheek / lab)
$septenary: #df0000; // Rood (fel) Vet MRI
$headingfont: "ff-meta-web-pro", sans-serif;
$basefont: "ff-meta-web-pro", sans-serif;
$seriffont: 'Georgia', serif;
$fontawesome: 'Font Awesome 5 Free';
$textcolor: #010101;