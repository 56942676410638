/* Footer */
footer {
	display: block;
	position: relative;
	color: $textcolor;
	height: auto;
	min-height: 150px;
	box-shadow: 0 0 25px rgba(#494949, .3);
	
	a {
		color: $textcolor;
		text-decoration: none;
	}
	
	.footer-top {
		background-color: #fff;
		
		.wrapper {
			padding: 20px;
		}
	}
	
	.footer-element {
		width: 100%;
		line-height: 1.6;
		margin-bottom: 15px;
		
		ul {
			margin: 0;
			line-height: 1.6;
			list-style: none;
		}
		
		ul li {
			display: block;
		}
		
		ul li a {
		}
	}
	
	.logo-footer {
		display: block;
		padding: 30px 15px;
		
		img {
			display: block;
			max-width: 240px;
		}
	}
	
	.footer-element.location {
		
		i {
			color: $primary;
			margin-right: 5px;
		}
		
		.contact-details > span {
			display: block;
			color: #808080;
			font-size: .9em;
		}
		
		a {
			color: #808080;
		}
	}
	
	.menu-heading {
		display: block;
		font-size: 1em;
		font-weight: 700;
		text-transform: uppercase;
		color: $primary;
	}
	
	.social-media {
		text-align: center;
	}
	
	.social-media .icon {
		display: inline-block;
		font-size: 1.2em;
		color: #fff;
		margin: 5px;
		width: 40px;
		height: 40px;
		line-height: 40px;
		border-radius: 40px;
		text-align: center;
		background-color: #2e2d2d;
		transition: all .25s ease-in-out;
		
		&.facebook:hover {
			background-color: #375b95;
		}
		
		&.twitter:hover {
			background-color: #00a3ee;
		}
		
		&.youtube:hover {
			background-color: #ea1f25;
		}
		
		&.linkedin:hover {
			background-color: #0079b2;
		}
	}
	
	.menus {
		display: flex;
		flex-wrap: wrap;
	
		.footer-element {
			width: 50%;
		}
	}
	
	.call-to-actions {
		
		.gform_wrapper {
			margin: 10px 0;
		}
		
		.gform_title {
			font-size: 1rem;
			text-transform: uppercase;
			color: $primary;
			margin-bottom: 10px;
		}
		
		.gform_wrapper .top_label .gfield_label {
			display: none;
		}
		
		.gform_wrapper li {
			margin: 0;
		}
		
		.gform_wrapper input[type='email'] {
			border: 1px solid #9f9f9f;
			border-radius: 4px;
			margin-bottom: 7px;
		}
		
		.gform_footer {
			text-align: center;
		}
		
		.gform_wrapper input[type='submit'] {
			background-color: #fff;
			border: 3px solid $primary;
			text-transform: uppercase;
			color: #a3a3a3;
			max-width: 70%;
			margin: 0 auto;
		}
	}
	
	.call-to-action {
		width: 50%;
		max-height: 90px;
		display: flex;
		flex-wrap: wrap;
		text-decoration: none;
		margin-bottom: 10px;
		
		.icon {
			color: $primary;
			background-color: #dcdcdc;
			width: 25px;
			height: 25px;
			line-height: 25px;
			border-radius: 25px;
			text-align: center;
		}
		
		.phone {
			display: block;
			width: 100%;
			color: $textcolor;
		}
			
		.phone-pretext {
			color: $textcolor;
		}
		
		.cta-text {
			padding-left: 5px;
			flex-grow: 1;
		}
	}
	
	.footer-bottom {
		background-color: $primary;
		
		.wrapper {
			padding: 10px;
			display: flex;
			flex-wrap: wrap;
		}
		
		ul.menu {
			margin: 0;
			flex-grow: 1;
			list-style: none;
		}
		
		ul.menu li {
			display: inline-block;
			vertical-align: middle;
			
			a {
				display: inline-block;
				padding: 0 5px;
				color: #fff;
			}
		
			&::after {
				content: "|";
				color: #fff;
			}
			
			&:last-child::after {
				content: "";
			}
		}
		
		.icons {
			text-align: right;
		}
		
		.icons .icon i {
			width: 25px;
			height: 25px;
			text-align: center;
			line-height: 25px;
			color: #fff;
		}
	}
		
	@include mq($from: minitablet) {
		
		.footer-top .wrapper {
			display: flex;
			flex-wrap: wrap;
		}
		
		.footer-top .footer-element.location {
			display: flex;
			flex-wrap: wrap;
		}
		
		.footer-top .logo-footer,
		.footer-top .contact-details {
			width: 50%;
		}
		
		.footer-top .contact-details {
			display: flex;
			justify-content: center;
			flex-direction: column;
		}
		
		.menus {
			display: flex;
			width: 100%;
			flex-wrap: nowrap;
			
			.footer-element {
				width: 50%;
			}
		}
		
		.call-to-actions {
			display: flex;
			flex-wrap: wrap;
		}
		
		.call-to-action {
			width: 100%;
		}
		
		.cta-button-wrapper,
		.newsletter {
			width: 50%;
		}
	}
	
	@include mq($from: tablet) {
		
		.call-to-actions .gform_wrapper input[type='submit'] {
			max-width: 100%;
		}
	}
	
	@include mq($from: desktop) {
		padding-top: 30px;
		
		.footer-top .footer-element.location {
			width: 25%;
			
			.logo-footer {
				padding: 0 15px;
				width: 100%;
			}
			
			.contact-details {
				width: 100%;
				padding-left: 15px;
			}
		}
		
		.menus {
			width: 50%;
			font-size: .9em;
		}
		
		.call-to-actions {
			width: 25%;
			margin-top: -50px;
			
			.cta-button-wrapper {
				width: 100%;
				text-align: right;
			}
			
			.cta-button-wrapper .call-to-action {
				margin-bottom: 20px;
				background-color: $primary;
				color: #fff;
				border-radius: 0 0 20px 20px;
				padding: 15px 20px;
				text-align: left;
				max-width: 220px;
				float: right;
				
				.icon {
					width: 35px;
					height: 35px;
					line-height: 35px;
					border-radius: 35px;
					margin-right: 10px;
				}
				
				.phone-pretext,
				.phone {
					color: #fff;
				}
				
				.phone {
					font-weight: 600;
				}
			}
			
			.newsletter {
				width: 100%;
				text-align: right;
				padding-left: 50px;
				
				.gform_wrapper .gform_body {
					padding: 0;
				}
				
				.gform_footer {
					width: 100%;
					padding-left: 50px;
				}
				
				input[type='submit'],
				input[type='email'] {
					
				}
			}
		}
	}
	
	@include mq($from: wide) {
	}
}