.row-type-gallery {
	padding: 20px 0;
	
	.owl-stage-outer {
	}
	
	.owl-nav {
		position: absolute;
		bottom: 54%;
		right: 0;
		left: 0;
	}
	
	.owl-nav .owl-prev,
	.owl-nav .owl-next {
		position: absolute;
		top: 50%;
		left: 0;
		height: 25px;
		width: 25px;
		line-height: 25px;
		margin-top: -12px;
		text-align: center;
		color: $primary;
		font-size: 1.3em;
		outline: none;
		background-color: transparent;
		pointer-events: all;
		transition: all .25s ease-in-out;
		
		&:hover {
			background-color: $secondary;
			color: #fff;
		}
		
		&.disabled {
			opacity: .5;
			cursor: not-allowed;
			
			&:hover {
				background-color: #fff;
				color: $textcolor;
			}
		}
	}
	
	.owl-nav .owl-next {
		left: auto;
		right: 0;
	}
	
	.gallery-carousel {
		padding: 0 45px;
	}
	
	&.row-white .owl-stage-outer::before { background-color: #fff; }
	&.row-primary .owl-stage-outer::before { background-color: $primary; }
	&.row-secondary .owl-stage-outer::before { background-color: $secondary; }
	&.row-tertiary .owl-stage-outer::before { background-color: $tertiary; }
	&.row-quaternary .owl-stage-outer::before { background-color: $quaternary; }
	&.row-quintenary .owl-stage-outer::before { background-color: $quintenary; }
	
	.gallery-item {
		position: relative;
		display: block;
		overflow: hidden;
	}
	
	.gallery-item .gallery-item--image {
		position: relative;
		overflow: hidden;
		z-index: 2;
		
		&::before {
			content: "\f067";
			font-family: $fontawesome;
			font-weight: 900;
			font-size: 1.3em;
			text-align: center;
			line-height: 50px;
			display: block;
			position: absolute;
			top: 50%;
			left: 50%;
			height: 50px;
			width: 50px;
			margin-left: -25px;
			margin-top: -25px;
			border-radius: 50px;
			color: #fff;
			background-color: $primary;
			transform: scale(0);
			z-index: 5;
			transition: all .25s ease-in-out;
		}
		
		&::after {
			content: "";
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			height: 100%;
			width: 100%;
			background-color: rgba($secondary, .5);
			opacity: 0;
			pointer-events: none;
			z-index: 4;
			transition: all .75s ease-in-out;
		}
		
		img {
			position: relative;
			border: 10px solid darken($tertiary, 8%);
			z-index: 1;
			transition: all .15s ease-in-out;
		}
	}
	
	.gallery-item .gallery-item--title {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: auto;
		padding-top: 30px;
		padding-bottom: 10px;
		padding-left: 15px;
		padding-right: 15px;
		padding: 10px 15px 10px 15px;
		font-weight: 700;
		color: $textcolor;
		background: rgba(#fff, .7);
		transition: bottom .25s ease-in-out;
		z-index: 5;
	}
	
	.gallery-item:hover {
		
		.gallery-item--image::before {
			transform: scale(1);
		}
		
		.gallery-item--image::after {
			opacity: 1;
		}
		
		.gallery-item--image img {
			transform: scale(1.03);
			border: 2px solid #fff;
		}
		
		.gallery-item--title {
			bottom: -50px;
		}
	}
	
	@include mq($from: desktop) {
		padding: 40px 0;
		
		.owl-nav .owl-prev,
		.owl-nav .owl-next {
			height: 40px;
			width: 40px;
			line-height: 40px;
			border-radius: 40px;
			font-size: 1.4em;
			color: $primary;
			background-color: transparent;
		}
		
		.gallery-carousel {
			padding: 0 45px;
		}
		
		.gallery-item .gallery-item--title {
			font-size: 1.3em;
			padding: 15px 25px 15px 25px;
		}
	}
	
	@include mq($from: wide) {
	}
}
